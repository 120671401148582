import axios, { AxiosHeaders, type AxiosRequestConfig } from 'axios';
import type { DigitalExperienceProvider, Widget } from '@/open-api';

export const KOOPID_API_PATH = 'KoopidPartnerServer/api/APIEndpoint/';

export const KOOPID_CONTENT_TYPE = 'application/dx.dialpad.com.v2+json';

export function getWorkflows(
    companyId: string,
    authToken: string,
    koopid: DigitalExperienceProvider
) {
    const headers: AxiosHeaders = new AxiosHeaders({
        ['Authorization']: authToken,
        ['X-Dialpad']: 'True'
    });
    const config: AxiosRequestConfig = {
        headers
    };
    const koopidURL = new URL(
        `${KOOPID_API_PATH}AppDesigner/${companyId}/Workflows?dpcompany=true&type=digital`,
        koopid.url
    );
    return axios.get<GetWorkflowsResponse>(koopidURL.toString(), config);
}

export function getDSSInstances(
    companyId: string,
    appId: string,
    authToken: string,
    koopid: DigitalExperienceProvider,
    newWebchatClient = false
) {
    const headers: AxiosHeaders = new AxiosHeaders({
        ['Authorization']: authToken,
        ['X-Dialpad']: 'True',
        ['Content-type']: KOOPID_CONTENT_TYPE,
        ['Accept']: KOOPID_CONTENT_TYPE
    });
    const config: AxiosRequestConfig = {
        headers
    };
    const koopidURL = new URL(
        `${KOOPID_API_PATH}Customization/EntryTag/ShortDSSLink/${companyId}/${appId}${newWebchatClient ? '?version=2.0' : ''}`,
        koopid.url
    );
    return axios.get<GetDSSInstancesResponse>(koopidURL.toString(), config);
}

export interface KoopidInstance extends Partial<Widget> {
    bots: KoopidBot[];
    providerId: number | string;
}

export interface KoopidBot {
    botname: string;
    botid: number;
    shortLink: string;
    url?: string;
    providerId?: string | number;
}

export interface GetDSSInstancesResponse {
    code: string;
    entity: {
        entrytags: KoopidBot[];
        providerid: number | string;
    };
}
export interface KoopidWorkflow {
    path: string;
    name: string;
    id: string;
}

export interface GetWorkflowsResponse {
    code: string;
    entity: KoopidWorkflow[];
}
