<template>
    <div class="d-d-flex d-fd-column d-h100p" ref="improveWrapper">
        <default-template-view
            :title="$t('Optimize')"
            :title-description="bylineCopy"
            :table-data="tabSelectedData"
            :columns="columnsByState"
            :is-fetching="getFetching(tabSelected)"
            :is-fetching-more="isFetchingMore"
            :has-loaded="hasLoaded"
            :total-items="total"
            :pagination="pagination"
            v-model:sort="sortBy"
            v-model:labels="tags"
            v-model:on-page-change="activePage"
            :has-error="getError(tabSelected)"
            hide-checkbox
            :items-per-page="itemsPerPage"
            :item-type="currentNodeType"
            hide-search
            @table-row-click="handleOpenResponse"
        >
            <template #actionButtons>
                <dt-button
                    v-feature="'node_editor'"
                    class="d-truncate"
                    size="xs"
                    @click="handleExport"
                    hidden
                >
                    {{ $t('Export') }}
                </dt-button>
            </template>
            <template #filters>
                <dt-tab-group size="sm" borderless id="optimize-tabs">
                    <template #tabs>
                        <dt-tab
                            v-for="optimizeTab in OPTIMIZE_TABS"
                            :id="optimizeTab.value"
                            :panel-id="`${optimizeTab.value}-panel`"
                            :key="optimizeTab"
                            @click="onTabClick(optimizeTab.value)"
                            :tab-class="{
                                'd-bar32': true,
                                'd-bgc-purple-100 d-fc-purple-400':
                                    tabSelected === optimizeTab.value
                            }"
                        >
                            {{
                                `${$t(optimizeTab.title)} ${!getFetching(optimizeTab.value) ? `(${getTotalHits(optimizeTab.value)})` : '(-)'}`
                            }}
                        </dt-tab>
                    </template>
                </dt-tab-group>
            </template>
        </default-template-view>
        <empty-state
            v-if="
                !getData(tabSelected)?.length &&
                !getFetching(tabSelected) &&
                !getError(tabSelected)
            "
            :item-type="currentNodeType"
            v-slot="{ hasFilters, hasSearchFilterOnly, itemType }"
        >
            <div
                v-if="!(hasFilters || hasSearchFilterOnly)"
                style="max-width: 280px"
            >
                <div class="d-mb4 d-headline-large">
                    <span v-if="OPTIMIZE_TAB.SNOOZED === itemType">
                        {{ $t(`No snoozed question groups`) }}
                    </span>
                    <span v-else>{{ $t(`No ${itemType}`) }}</span>
                </div>

                {{ fetchEmptyStateDescription(itemType) }}
            </div>
        </empty-state>
        <confirmation-modal
            :title="
                $t(
                    isUnmuteConfirmationModal
                        ? 'Unmute question'
                        : 'Unsnooze question group'
                )
            "
            :description="confirmationModalDescription"
            kind="default"
            :button-title="
                $t(
                    isUnmuteConfirmationModal
                        ? 'Yes, unmute question'
                        : 'Yes, unsnooze question group'
                )
            "
            v-model:show-prompt="showPrompt"
            @confirm="handleConfirm"
            no-translation
        />
    </div>
</template>

<script lang="tsx">
import { defineComponent, h, Fragment, inject } from 'vue';
import { Observable, Subject, Subscription } from 'rxjs';

import {
    Knowledgebase,
    ClusterDetail,
    PatchClusterRequest,
    Widget
} from '@/open-api';
import { NodeStatus, NodeType } from '@/open-api';

import {
    DtButton,
    DtTooltip,
    DtSelectMenu,
    DtChip,
    DtTabGroup,
    DtTab
} from '@dialpad/dialtone/vue3';

import { handleRequest, type IFilter, updateQueryParams } from '@/utils/Common';
import type { INotification } from '@/utils/types/Notification';
import { IMPROVE_STATES, CONSOLIDATE_OPTIONS } from '@/utils/types/Improve';
import { NOTICE_KINDS, OPTIMIZE_TAB, OPTIMIZE_TABS } from '@/utils/Constants';

import DefaultTemplateView from '@/views/DefaultTemplateView.vue';

import type { IBaseTableColumn } from '@/components/base-table/BaseTable.types';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import EmptyState from '@/components/empty-state/EmptyState.vue';
import SidebarToggle from '@/components/sidebar-drawer/SidebarToggle.vue';
import TemplateButton from '@/components/template-button/TemplateButton.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';

import type { ApiService } from '@/services/Api.service';
import { APP_BY_LICENSE_TYPE } from '@/utils/types/App';
import { Response } from '@/utils/Common';

export default defineComponent({
    components: {
        SidebarToggle,
        DefaultTemplateView,
        FilterPopover,
        EmptyState,
        ConfirmationModal,
        DtChip,
        DtSelectMenu,
        DtTooltip,
        DtButton,
        DtTabGroup,
        DtTab
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const onScrollY$: Observable<any> = (
            inject('onRootScrollY$')! as Subject<Event>
        ).asObservable();

        return {
            orgId,
            onScrollY$
        };
    },
    mounted() {
        const paramSubTab = this.$route?.query?.subTab;

        const currentTab = OPTIMIZE_TABS.find(
            (optimizeTab: any) => optimizeTab.value === paramSubTab
        )
            ? paramSubTab
            : OPTIMIZE_TABS[0].value;

        if (paramSubTab !== currentTab) {
            updateQueryParams(this.$router, { subTab: currentTab }, false);
        }

        this.tabSelected = currentTab;
        this.selectNodeTypeBySubTab(currentTab);
        this.fetchAllTabs();

        this.rootOnScrollSubscription = this.onScrollY$.subscribe(
            this.onScroll
        );
    },
    beforeUnmount() {
        this.rootOnScrollSubscription?.unsubscribe();
    },
    watch: {
        showPrompt(open: boolean) {
            if (!open) {
                this.selectedCluster = null;
                this.selectedQuery = null;
                this.confirmationModalType = '';
            }
        },
        sortBy: {
            handler(newSort) {
                if (newSort) {
                    this.fetchTabData(this.tabSelected, false);
                }
            }
        },
        $route(to, from) {
            if (!to.query?.subTab) {
                updateQueryParams(
                    this.$router,
                    { subTab: this.tabSelected },
                    true
                );

                this.fetchTabData(this.tabSelected);
            } else if (
                from.query?.subTab &&
                from.query?.subTab !== to.query?.subTab
            ) {
                this.fetchTabData(to.query?.subTab);
            }
        }
    },
    computed: {
        OPTIMIZE_TAB() {
            return OPTIMIZE_TAB;
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        NodeType() {
            return NodeType;
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        chatbot(): Widget {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        },
        improveWrapperWidth(): number {
            return (this.$refs.improveWrapper as HTMLElement).clientWidth - 24;
        },
        confirmationModalDescription(): string {
            return this.isUnmuteConfirmationModal
                ? `${this.$t('The question')} "${this.selectedQuery?.query}" ${this.$t('will be unmuted. If this question continues to be unanswered in the future, it will appear on the “Unanswered questions” page. Are you sure you want to continue?')}`
                : this.$t(
                      'This action will unsnooze all of the questions in the question group. You can always snooze question groups from the “Unanswered questions” page. Are you sure you want to unsnooze all the questions in this group?'
                  );
        },
        bylineCopy(): string {
            return this.$t(
                `Address unanswered questions or actionable insights to improve your ${this.chatbotName}'s content and performance.`
            );
        },
        isUnmuteConfirmationModal(): boolean {
            return this.confirmationModalType === 'unmute';
        },
        columnsByState(): IBaseTableColumn<any>[] {
            switch (this.tabSelected) {
                case 'missing_knowledge':
                    return [
                        {
                            title: 'Volume',
                            type: 'string',
                            width: '13rem',
                            key: 'query_count',
                            sortable: true,
                            sort: 'volume',
                            tooltip: {
                                type: 'alert-circle',
                                message:
                                    'How many times this question group occurred',
                                placement: 'top-start'
                            }
                        },
                        {
                            type: 'string',
                            title: 'Question group',
                            sort: 'title',
                            sortable: true,
                            template: (column) => {
                                return h(
                                    'div',
                                    {
                                        class: 'd-fc-black-900 d-truncate d-p16',
                                        style: `width: ${
                                            this.improveWrapperWidth / 2
                                        }px`
                                    },
                                    h(
                                        'p',
                                        { class: 'd-truncate' },
                                        column.title
                                    )
                                );
                            }
                        }
                    ];
                case 'muted_questions':
                    return [
                        {
                            key: 'query',
                            type: 'string',
                            title: 'Question',
                            sort: 'query',
                            sortable: true,
                            template: (column) => {
                                return h(
                                    <div className="d-p16 d-d-flex d-jc-space-between d-ai-center d-c-default">
                                        <div className="d-fc-black-900 d-truncate d-to-ellipsis d-py4">
                                            {column.query}
                                        </div>
                                        <TemplateButton
                                            title="Unmute"
                                            onClick={() =>
                                                this.handleShowUnmuteConfirmationModal(
                                                    column
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        },
                        {
                            key: 'created_at',
                            type: 'datetime',
                            title: 'Muted at',
                            sort: 'created_at',
                            sortable: true,
                            defaultCursor: true
                        }
                    ];
                case 'broken_content':
                    return [
                        {
                            type: 'string',
                            title: 'Title',
                            sortable: false,
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column?.node?.title}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ];
                case 'low_confidence':
                    return [
                        {
                            title: 'Questions',
                            type: 'string',
                            sortable: false,
                            width: '10rem',
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900 d-d-flex d-jc-flex-end">
                                                {column.counters?.queries}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        },
                        {
                            key: 'issue',
                            type: 'string',
                            title: 'Issue',
                            sortable: false,
                            width: '30rem',
                            template: (column) => {
                                // TODO
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                More questions needed
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        },
                        {
                            key: 'title',
                            type: 'string',
                            title: 'Response title',
                            sort: 'title',
                            sortable: true,
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column.title}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        }
                    ];
                case 'outdated_responses':
                    return [
                        {
                            type: 'string',
                            title: 'Response title',
                            sort: 'title',
                            sortable: true,
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900">
                                                {column?.title}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            }
                        },
                        {
                            key: 'updated_at',
                            type: 'datetime',
                            title: 'Last updated',
                            sort: 'updated_at',
                            sortable: true
                        }
                    ];
                case 'snoozed':
                    return [
                        {
                            title: 'Volume',
                            type: 'string',
                            width: '13rem',
                            sortable: false,
                            template: (column) => {
                                return h(
                                    <Fragment>
                                        <div className="d-p16">
                                            <p className="d-truncate d-fc-black-900 d-d-flex d-jc-flex-end">
                                                {column.query_count}
                                            </p>
                                        </div>
                                    </Fragment>
                                );
                            },
                            tooltip: {
                                type: 'alert-circle',
                                message:
                                    'How many times this question group occurred',
                                placement: 'top-start'
                            }
                        },
                        {
                            type: 'string',
                            title: 'Question group',
                            sortable: false,
                            template: (column) => {
                                return h(
                                    <div className="d-p16 d-d-flex d-jc-space-between d-c-default">
                                        <div className="d-fc-black-900 d-truncate d-to-ellipsis d-py4">
                                            {column.title}
                                        </div>
                                        <TemplateButton
                                            title="Unsnooze"
                                            onClick={() =>
                                                this.handleShowUnsnoozeConfirmationModal(
                                                    column
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        }
                    ];
                default:
                    return [];
            }
        },
        chatbotName(): string {
            return APP_BY_LICENSE_TYPE.SINGULAR[this.chatbot.license_type];
        },
        chatbotNamePluralized(): string {
            return APP_BY_LICENSE_TYPE.PLURAL[this.chatbot.license_type];
        },
        missingKnowledgeData(): ClusterDetail[] {
            return this.$store.getters[
                `${this.orgId}/optimize/missingKnowledge/list`
            ];
        },
        missingKnowledgeIsFetching(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/missingKnowledge/isFetching`
            ];
        },
        missingKnowledgeHasError(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/missingKnowledge/hasError`
            ];
        },
        missingKnowledgeTotalHits(): number {
            return this.$store.getters[
                `${this.orgId}/optimize/missingKnowledge/totalHits`
            ];
        },
        brokenContentData(): ClusterDetail[] {
            return this.$store.getters[
                `${this.orgId}/optimize/brokenContent/list`
            ];
        },
        brokenContentIsFetching(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/brokenContent/isFetching`
            ];
        },
        brokenContentHasError(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/brokenContent/hasError`
            ];
        },
        brokenContentTotalHits(): number {
            return this.$store.getters[
                `${this.orgId}/optimize/brokenContent/totalHits`
            ];
        },
        lowConfidenceData(): ClusterDetail[] {
            return this.$store.getters[
                `${this.orgId}/optimize/lowConfidence/list`
            ];
        },
        lowConfidenceIsFetching(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/lowConfidence/isFetching`
            ];
        },
        lowConfidenceHasError(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/lowConfidence/hasError`
            ];
        },
        lowConfidenceTotalHits(): number {
            return this.$store.getters[
                `${this.orgId}/optimize/lowConfidence/totalHits`
            ];
        },
        mutedQuestionsData(): ClusterDetail[] {
            return this.$store.getters[
                `${this.orgId}/optimize/mutedQuestions/list`
            ];
        },
        mutedQuestionsIsFetching(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/mutedQuestions/isFetching`
            ];
        },
        mutedQuestionsHasError(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/mutedQuestions/hasError`
            ];
        },
        mutedQuestionsTotalHits(): number {
            return this.$store.getters[
                `${this.orgId}/optimize/mutedQuestions/totalHits`
            ];
        },
        snoozedData(): ClusterDetail[] {
            return this.$store.getters[`${this.orgId}/optimize/snoozed/list`];
        },
        snoozedIsFetching(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/snoozed/isFetching`
            ];
        },
        snoozedHasError(): boolean {
            return this.$store.getters[
                `${this.orgId}/optimize/snoozed/hasError`
            ];
        },
        snoozedTotalHits(): number {
            return this.$store.getters[
                `${this.orgId}/optimize/snoozed/totalHits`
            ];
        },
        tabSelectedData() {
            return this.getData(this.tabSelected);
        }
    },
    methods: {
        fetchAllTabs() {
            this.fetchMissingKnowledge();
            this.fetchBroken();
            this.fetchLowConfidence();
            this.fetchMutedQuestions();
            this.fetchSnoozed();
        },
        async fetchMissingKnowledge() {
            await this.$store.dispatch(
                `${this.orgId}/optimize/missingKnowledge/fetch`,
                {
                    kbId: this.knowledgebase?.id,
                    order: !this.sortBy?.sort
                        ? undefined
                        : this.sortBy?.asc
                          ? 'asc'
                          : 'desc',
                    limit: this.itemsPerPage
                }
            );
        },
        async fetchSnoozed() {
            await this.$store.dispatch(`${this.orgId}/optimize/snoozed/fetch`, {
                kbId: this.knowledgebase?.id,
                order: !this.sortBy?.sort
                    ? undefined
                    : this.sortBy?.asc
                      ? 'asc'
                      : 'desc',
                limit: this.itemsPerPage
            });
        },
        async fetchMutedQuestions() {
            await this.$store.dispatch(
                `${this.orgId}/optimize/mutedQuestions/fetch`,
                {
                    kbId: this.knowledgebase?.id,
                    order: !this.sortBy?.sort
                        ? undefined
                        : this.sortBy?.asc
                          ? 'asc'
                          : 'desc',
                    limit: this.itemsPerPage
                }
            );
        },
        async fetchMoreMutedQueries() {
            await this.$store.dispatch(
                `${this.orgId}/optimize/mutedQuestions/fetchMore`,
                {
                    kbId: this.knowledgebase?.id,
                    order: !this.sortBy?.sort
                        ? undefined
                        : this.sortBy?.asc
                          ? 'asc'
                          : 'desc',
                    limit: this.itemsPerPage
                }
            );
        },
        async fetchBroken() {
            await this.$store.dispatch(
                `${this.orgId}/optimize/brokenContent/fetch`,
                {
                    kbId: this.knowledgebase?.id
                }
            );
        },
        async fetchLowConfidence() {
            await this.$store.dispatch(
                `${this.orgId}/optimize/lowConfidence/fetch`,
                {
                    kbId: this.knowledgebase?.id,
                    order: !this.sortBy?.sort
                        ? undefined
                        : this.sortBy?.asc
                          ? 'asc'
                          : 'desc',
                    limit: this.itemsPerPage
                }
            );
        },
        async handleUnmute() {
            if (!this.selectedQuery?.id) {
                return;
            }
            const res: Response<void> = await this.$store.dispatch(
                `${this.orgId}/optimize/mutedQuestions/unmute`,
                {
                    kbId: this.knowledgebase?.id,
                    selectedQueryId: this.selectedQuery!.id
                }
            );
            this.hasLoaded = true;

            if (!res.error) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    message: this.$t('Unmuted successfully')
                } as INotification);

                await this.fetchMutedQuestions();
            }
        },
        handleOpenResponse(rowData: any) {
            if (this.tabSelected === 'missing_knowledge') {
                this.$router.push({
                    name: 'optimize_wizard',
                    params: {
                        ...this.$router.currentRoute.value.params,
                        clusterId: rowData.data.id
                    },
                    query: {
                        ...this.$router.currentRoute.value.query
                    }
                });
            } else if (
                this.tabSelected === 'snoozed' ||
                this.tabSelected === 'muted_questions'
            ) {
                return;
            } else {
                const nodeId =
                    this.tabSelected === 'broken_content'
                        ? rowData.data.node.id
                        : rowData.data.id;
                this.$router.push({
                    name: 'node_editor',
                    params: {
                        ...this.$router.currentRoute.value.params,
                        nodeId
                    },
                    query: {
                        ...this.$router.currentRoute.value.query,
                        nodeType: NodeType.Response,
                        tab: 'improve',
                        subTab: this.tabSelected
                    }
                });
            }
        },
        onTabClick(subTab: string) {
            updateQueryParams(this.$router, { subTab: subTab }, true);
        },
        selectNodeTypeBySubTab(subTab: string) {
            switch (subTab) {
                case 'missing_knowledge':
                    this.currentNodeType = OPTIMIZE_TAB.UNANSWERED_QUESTIONS;
                    return;
                case 'broken_content':
                    this.currentNodeType = OPTIMIZE_TAB.BROKEN_CONTENTS;
                    return;
                case 'low_confidence':
                    this.currentNodeType =
                        OPTIMIZE_TAB.LOW_CONFIDENCE_RESPONSES;
                    return;
                case 'muted_questions':
                    this.currentNodeType = OPTIMIZE_TAB.MUTED_QUESTIONS;
                    return;
                case 'snoozed':
                    this.currentNodeType = OPTIMIZE_TAB.SNOOZED;
                    return;
                default:
                    this.currentNodeType = 'questions';
                    return;
            }
        },

        fetchTabData(subTab: string, resetSort: boolean = true) {
            this.tabSelected = subTab;
            this.selectNodeTypeBySubTab(subTab);
            switch (subTab) {
                case 'missing_knowledge':
                    this.pagination = true;
                    this.fetchMissingKnowledge();
                    return;
                case 'broken_content':
                    this.pagination = true;
                    this.fetchBroken();
                    return;
                case 'low_confidence':
                    this.pagination = true;
                    if (resetSort)
                        this.$store.dispatch(
                            `${this.orgId}/optimize/lowConfidence/resetSort`
                        );
                    this.fetchLowConfidence();
                    return;
                case 'muted_questions':
                    this.pagination = false;
                    if (resetSort)
                        this.$store.dispatch(
                            `${this.orgId}/optimize/mutedQuestions/resetSort`
                        );
                    this.fetchMutedQuestions();
                    return;
                case 'snoozed':
                    this.pagination = true;
                    this.fetchSnoozed();
                    return;
                default:
                    this.pagination = true;
                    return;
            }
        },
        fetchEmptyStateDescription(optimizeTab: OPTIMIZE_TAB) {
            switch (optimizeTab.toString()) {
                case OPTIMIZE_TAB.UNANSWERED_QUESTIONS:
                    return this.$t(
                        'Come back tomorrow to see new question groups'
                    );
                case OPTIMIZE_TAB.BROKEN_CONTENTS:
                case OPTIMIZE_TAB.LOW_CONFIDENCE_RESPONSES:
                case OPTIMIZE_TAB.OUTDATED_RESPONSES:
                    return this.$t('Visit this tab regularly to address');
                case OPTIMIZE_TAB.MUTED_QUESTIONS:
                    return this.$t(
                        'You will be able to review and unmute questions on this page if you mute any unanswered questions'
                    );
                case OPTIMIZE_TAB.SNOOZED:
                    return this.$t(
                        'You will be able to review and unsnooze question groups on this page if you snooze any question groups'
                    );
            }
        },
        handleShowUnsnoozeConfirmationModal(cluster: ClusterDetail) {
            this.selectedCluster = cluster;
            this.showPrompt = true;
            this.confirmationModalType = 'unsnooze';
        },
        handleShowUnmuteConfirmationModal(queryData: any) {
            this.selectedQuery = queryData;
            this.showPrompt = true;
            this.confirmationModalType = 'unmute';
        },
        async handleUnsnooze() {
            this.isLoading = true;
            const body: PatchClusterRequest = {
                cluster: {
                    status: 'active'
                }
            };
            const res = await handleRequest<ClusterDetail>(
                this.apiService.improve.patchCluster(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.selectedCluster.id,
                    NodeType.Response,
                    body
                ),
                this.orgId
            );
            this.isLoading = false;

            if (!res.error) {
                updateQueryParams(
                    this.$router,
                    { subTab: 'missing_knowledge' },
                    true
                );

                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    message: this.$t('Unsnooze successfully')
                } as INotification);
            }
        },
        handleConfirm() {
            this.isUnmuteConfirmationModal
                ? this.handleUnmute()
                : this.handleUnsnooze();
        },
        handleExport() {},
        onScroll(e: any) {
            if (
                this.mutedQuestionsIsFetching ||
                this.isFetchingMore ||
                this.hasError
            )
                return;
            const { scrollTop, offsetHeight, scrollHeight } = e.target;
            if (scrollTop + offsetHeight >= scrollHeight - 1) {
                this.fetchMoreFromCursor();
            }
        },
        async fetchMoreFromCursor() {
            this.isFetchingMore = true;
            // currently only muted queries support fetching more from cursor
            await this.fetchMoreMutedQueries();
            this.isFetchingMore = false;
        },
        getTotalHits(tab) {
            switch (tab) {
                case 'missing_knowledge':
                    return this.missingKnowledgeTotalHits;
                case 'broken_content':
                    return this.brokenContentTotalHits;
                case 'low_confidence':
                    return this.lowConfidenceTotalHits;
                case 'outdated_responses':
                    return this.outdatedResponsesTotalHits;
                case 'muted_questions':
                    return this.mutedQuestionsTotalHits;
                case 'snoozed':
                    return this.snoozedTotalHits;
                default:
                    return 0;
            }
        },
        getData(tab) {
            switch (tab) {
                case 'missing_knowledge':
                    return this.missingKnowledgeData;
                case 'broken_content':
                    return this.brokenContentData;
                case 'low_confidence':
                    return this.lowConfidenceData;
                case 'outdated_responses':
                    return this.outdatedResponsesData;
                case 'muted_questions':
                    return this.mutedQuestionsData;
                case 'snoozed':
                    return this.snoozedData;
                default:
                    return [];
            }
        },
        getFetching(tab) {
            switch (tab) {
                case 'missing_knowledge':
                    return this.missingKnowledgeIsFetching;
                case 'broken_content':
                    return this.brokenContentIsFetching;
                case 'low_confidence':
                    return this.lowConfidenceIsFetching;
                case 'outdated_responses':
                    return this.outdatedResponsesIsFetching;
                case 'muted_questions':
                    return this.mutedQuestionsIsFetching;
                case 'snoozed':
                    return this.snoozedIsFetching;
                default:
                    return false;
            }
        },
        getError(tab) {
            switch (tab) {
                case 'missing_knowledge':
                    return this.missingKnowledgeHasError;
                case 'broken_content':
                    return this.brokenContentHasError;
                case 'low_confidence':
                    return this.lowConfidenceHasError;
                case 'outdated_responses':
                    return this.outdatedResponsesHasError;
                case 'muted_questions':
                    return this.mutedQuestionsHasError;
                case 'snoozed':
                    return this.snoozedHasError;
                default:
                    return false;
            }
        }
    },
    data() {
        return {
            isFetching: false,
            isFetchingMore: false,
            canFetchMore: true,
            cursor: '',
            hasLoaded: false,
            hasError: false,
            activePage: 1,
            total: 0,
            itemsPerPage: 100,
            pagination: true,
            sortBy: { sort: 'title', asc: true },
            selectedConsolidateOption: [
                // @ts-ignore
                this.$route.query.option || CONSOLIDATE_OPTIONS[0].value
            ] as string[],
            markdown: '',
            IMPROVE_STATES,
            OPTIMIZE_TABS,
            tags: [] as string[],
            nodeStatus: NodeStatus,
            currentNodeType: 'questions' as any,
            improveStateFilters: [
                ...Object.values(IMPROVE_STATES).map((status) => ({
                    label: status,
                    value: status
                }))
            ] as IFilter[],
            selectedImproveState: [
                // @ts-ignore
                this.$route.query.state || 'Consolidate'
            ] as string[],
            tabSelected: '',
            confirmationModalType: '',
            showPrompt: false,
            selectedCluster: null as any,
            selectedQuery: null as any,
            rootOnScrollSubscription: undefined as Subscription | undefined
        };
    }
});
</script>

<style lang="less">
#optimize-tabs .d-tablist button::after {
    display: none;
}

#optimize-tabs .d-tab--selected {
    color: var(--dt-color-foreground-primary);
}
</style>
