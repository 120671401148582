import type { Module } from 'vuex';
import {
    ListNodesResponse,
    NodeDetail,
    NodeType,
    Organisation
} from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import { handleRequest } from '@/utils/Common';

export interface LowConfidenceModuleState {
    data: NodeDetail[];
    fetching: boolean;
    error: boolean;
    totalHits?: number;
    kbId: string;
    order?: string;
    sort?: string;
    limit?: number;
}

export default function (org: Organisation) {
    return {
        namespaced: true,
        state: {
            data: [],
            fetching: false,
            error: false,
            totalHits: 0,
            cursor: undefined,
            kbId: undefined
        },
        getters: {
            list(state): NodeDetail[] {
                return state.data;
            },
            isFetching(state): boolean {
                return state.fetching;
            },
            totalHits(state): number {
                return state.totalHits;
            },
            hasError(state): number {
                return state.error;
            },
            cursor(state): string {
                return state.cursor;
            },
            canFetchMore(state): boolean {
                return state.canFetchMore;
            },
            kbId(state) {
                return state.kbId;
            }
        },
        actions: {
            async fetch(
                { commit, rootGetters },
                { kbId, sort, order, limit, disableFetching }
            ) {
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                if (!disableFetching) commit('setFetching', true);

                commit('setError', false);
                commit('setKbId', kbId);
                commit('setSort', sort);
                commit('setOrder', order);
                commit('setLimit', limit);

                try {
                    const res = await handleRequest<ListNodesResponse>(
                        apiService.improve.listUnderRepresentedNodes(
                            authToken,
                            kbId,
                            NodeType.Response,
                            undefined,
                            sort,
                            order,
                            limit
                        ),
                        org.id
                    );
                    commit('setData', res.data.nodes);
                    commit('setTotalHits', res.data.nodes.length);
                } catch (e) {
                    commit('setError', true);
                }

                if (!disableFetching) commit('setFetching', false);
            },
            resetSort({ commit }) {
                commit('setSort', 'title');
                commit('setOrder', 'asc');
            }
        },
        mutations: {
            setData(state, data: NodeDetail[]) {
                state.data = data;
            },
            setTotalHits(state, totalHits: number) {
                state.totalHits = totalHits;
            },
            setFetching(state, fetching: boolean) {
                state.fetching = fetching;
            },
            setError(state, error: boolean) {
                state.error = error;
            },
            setSort(state, sort?: string) {
                state.sort = sort;
            },
            setOrder(state, order?: 'asc' | 'desc') {
                state.order = order;
            },
            setLimit(state, limit: number) {
                state.limit = limit;
            },

            setKbId(state, kbId: string) {
                state.kbId = kbId;
            }
        }
    } as Module<LowConfidenceModuleState, any>;
}
