<template>
    <div
        @dragenter.prevent="toggleActive"
        @dragleave.prevent="toggleActive"
        @dragover.prevent
        @drop.prevent="onSelectFiles"
        class="d-h100p d-ps-relative"
        :class="{
            'd-ba d-baw2 d-bas-dashed d-bar4 d-bc-black-400':
                !isDrawer && !failed,
            'd-bc-purple-200': active
        }"
        data-qa="drop-file-wrapper"
    >
        <div
            class="d-bgc-primary d-ps-absolute d-d-flex d-ai-center d-jc-center d-fd-column d-h100p d-w100p"
            v-if="active && !failed"
        >
            <spot-file-upload class="d-w64" />
            <span>{{ $t('Drop files to attach') }}</span>
        </div>
        <div
            v-if="failed"
            class="d-ba d-baw1 d-bar4 d-bc-black-400 d-d-flex d-ai-center d-jc-center d-fd-column d-p8"
            :class="dropzoneClasses"
        >
            <dt-icon
                name="alert-triangle"
                size="500"
                class="d-fc-primary d-mb8 d-fc-red-300 d-p16 d-bgc-moderate d-bar4"
            />
            <span class="d-fc-black-900">{{ $t('Upload failed') }}</span>
            <span class="d-fc-black-600 d-mb8">
                {{
                    wrongFileType
                        ? `${$t('File types accepted')}: ${uploadSubtext}`
                        : `${$t('Max file size')} (${typeMaxSize}mb) ${$t(
                              'exceeded'
                          )} • ${tempFileSize}mb`
                }}
            </span>
            <dt-button
                class="d-mb4"
                size="xs"
                importance="clear"
                kind="danger"
                @click="handleUploadAnother"
            >
                {{ `${$t('Upload another')} ${typeText}` }}
            </dt-button>
        </div>
        <div
            v-if="!failed"
            class="d-h100p"
            :class="{
                'd-o5 d-bgo10 ': active
            }"
        >
            <div v-if="!hideDescription" class="d-mb16">
                <p>
                    {{ $t('This Knowledge base is in') }}{{ ' ' }}
                    <b>{{ convertLocaleToLanguage(knowledgebase?.locale) }}</b>
                </p>
                <p>
                    {{
                        $t(
                            'Documents in other languages might affect search results'
                        )
                    }}
                </p>
            </div>
            <div
                class="d-td200 d-d-flex d-jc-center d-bgc-secondary h:d-bgc-moderate"
                :class="dropzoneClasses"
            >
                <label
                    for="dropzoneFile"
                    class="d-d-flex d-fd-column d-ai-center d-fc-purple-400 d-c-pointer d-p24 d-w100p d-jc-center d-fs-200"
                    :class="{
                        'd-ba d-baw2 d-bas-dashed d-bar4 d-bc-black-400':
                            isDrawer
                    }"
                >
                    <dt-icon
                        name="upload-cloud"
                        size="500"
                        class="d-mr4 d-fc-primary d-mb8"
                    />
                    <span class="d-d-flex d-ai-center">
                        <span class="d-mr4">{{ $t('Click to upload') }}</span>
                        <span class="d-fc-black-900">{{ uploadText }}</span>
                    </span>
                    <span class="d-fc-black-600">{{ uploadSubtext }}</span>
                </label>
                <input
                    :multiple="isMultiple"
                    type="file"
                    id="dropzoneFile"
                    class="dropzoneFile d-d-none"
                    :accept="acceptedFileFormats.join(',')"
                    @change="onSelectFiles"
                />
            </div>
            <div class="d-d-flex" v-if="dropzoneFiles?.length">
                <h4 class="d-py16">{{ $t('Upload queue') }}</h4>
                <dt-button link class="d-ml-auto" @click="addLabels">
                    {{ $t('Add labels') }}
                </dt-button>
            </div>
            <div class="d-mb16" v-if="showAddLabels">
                <base-combobox
                    data-qa="labels-input"
                    :item-list="tags"
                    v-model:selected-items="selectedLabels"
                />
            </div>
            <div
                class="d-d-flex d-ai-center d-py12 d-bb d-baw1 d-bc-black-300 d-ff-row-wrap d-mb16"
                v-for="(uploadedDocument, i) in dropzoneFiles"
                :key="uploadedDocument.file.name"
            >
                <uploaded-file
                    :upload-file="uploadedDocument"
                    :index="i as number"
                    @remove-file="handleRemoveClick"
                    @override="handleOverride"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType, ref } from 'vue';
import { DtButton, DtIcon } from '@dialpad/dialtone/vue3';
// @ts-ignore
import SpotFileUpload from '@dialpad/dialtone/css/vue/spot/SpotFileUpload.vue';
import UploadedFile from '@/components/upload-drawer/UploadedFile.vue';
import {
    type Knowledgebase,
    type ListTagsOKBody,
    NodeStatus
} from '@/open-api';
import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import {
    ACCEPTED_DOC_FORMATS,
    ACCEPTED_IMAGE_FORMATS,
    ACCEPTED_VIDEO_FORMATS
} from '@/utils/Constants';
import {
    handleRequest,
    unionByName,
    convertLocaleToLanguage
} from '@/utils/Common';
import type { IUploadedFile } from './UploadFile.types';

export default defineComponent({
    props: {
        uploadText: {
            type: String as PropType<string>
        },
        uploadSubtext: {
            type: String as PropType<string>
        },
        dropzoneFiles: {
            type: Array as PropType<IUploadedFile[] | any[]>,
            required: true
        },
        selectedDocLabels: {
            type: Array as PropType<string[]>
        },
        hideDescription: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        fileTypeAccepted: {
            type: String as PropType<'DOC' | 'IMAGE' | 'VIDEO'>,
            default: 'DOC'
        },
        dropzoneClasses: {
            type: String as PropType<string>
        },
        isDrawer: {
            type: Boolean as PropType<boolean>
        },
        isMultiple: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        BaseCombobox,
        DtButton,
        DtIcon,
        SpotFileUpload,
        UploadedFile
    },
    mounted() {
        if (!this.tags?.length) this.fetchTags();
    },
    methods: {
        convertLocaleToLanguage,
        onSelectFiles(e: any) {
            if (!e) return;

            let eventFiles;
            if (e.dataTransfer) {
                this.toggleActive();
                eventFiles = Array.from(e.dataTransfer!.files);
            } else {
                eventFiles = Array.from(e.target?.files).filter((f: any) =>
                    this.isFileAccepted(f.type)
                );
            }

            const uploadedFiles = eventFiles.map((file: any) => {
                this.tempFileSize = parseFloat(
                    (file.size / 1024 ** 2).toFixed(2)
                );
                this.wrongFileType = !this.acceptedFileFormats.some(
                    (acceptedFormat: string) => acceptedFormat === file.type
                );

                if (
                    this.tempFileSize > this.typeMaxSize ||
                    this.wrongFileType
                ) {
                    e.preventDefault();
                    return null;
                }

                return {
                    file: file,
                    message: '',
                    status: '',
                    override: false
                };
            });

            if (uploadedFiles?.[0] === null) {
                this.failed = true;
                return;
            }

            this.$emit(
                'update:dropzoneFiles',
                unionByName(this.dropzoneFiles, uploadedFiles)
            );

            // Reset input field
            if (e.target) {
                e.target.value = '';
            }
        },
        addLabels() {
            this.showAddLabels = !this.showAddLabels;
            this.selectedLabels = [];
        },
        handleRemoveClick(file: any) {
            this.showAddLabels = false;
            this.$emit(
                'update:dropzoneFiles',
                this.dropzoneFiles.filter(
                    (el: any) => el.file.name !== file.file.name
                )
            );
        },
        handleOverride(file: any) {
            this.$emit(
                'update:dropzoneFiles',
                this.dropzoneFiles.map((el: any) => {
                    if (el.file.name === file.file.name) {
                        return {
                            ...el,
                            override: true,
                            message: this.$t(
                                'Replace with existing file. Please publish again to override.'
                            )
                        };
                    }

                    return el;
                })
            );
        },
        async fetchTags() {
            this.isComboboxFetching = true;
            this.labels = { tags: [] };
            const res = await handleRequest<ListTagsOKBody>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.knowledge.listTags(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    NodeStatus.Published
                ),
                this.orgId
            );
            this.isComboboxFetching = false;
            this.hasComboboxError = !!res.error;
            if (res?.data) this.labels = res.data;
        },
        isFileAccepted(type: string) {
            return this.acceptedFileFormats.some((el) => el === type);
        },
        handleUploadAnother() {
            this.failed = false;
            this.tempFileSize = null;
        }
    },
    computed: {
        isDocType(): boolean {
            return this.fileTypeAccepted === 'DOC';
        },
        isImageType(): boolean {
            return this.fileTypeAccepted === 'IMAGE';
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        acceptedFileFormats(): string[] {
            return this.isDocType
                ? ACCEPTED_DOC_FORMATS
                : this.isImageType
                  ? ACCEPTED_IMAGE_FORMATS
                  : ACCEPTED_VIDEO_FORMATS;
        },
        tags(): string[] {
            return this.labels?.tags || [];
        },
        selectedLabels: {
            get(): string[] {
                return this.selectedDocLabels as string[];
            },
            set(selectedLabels: string[]) {
                this.$emit('update:selectedDocLabels', selectedLabels);
            }
        },
        typeText(): string {
            return this.fileTypeAccepted === 'DOC'
                ? this.$t('document')
                : this.fileTypeAccepted === 'IMAGE'
                  ? this.$t('image')
                  : this.$t('video');
        },
        typeMaxSize(): number {
            return this.fileTypeAccepted === 'DOC'
                ? 5
                : this.fileTypeAccepted === 'IMAGE'
                  ? 5
                  : 100;
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const active = ref(false);

        const toggleActive = () => {
            active.value = !active.value;
        };
        return { active, toggleActive, orgId };
    },
    emits: ['update:dropzoneFiles', 'update:selectedDocLabels'],
    data() {
        return {
            showAddLabels: false,
            hasComboboxError: false,
            isComboboxFetching: false,
            labels: { tags: [] } as ListTagsOKBody,
            failed: false,
            tempFileSize: null as null | number,
            wrongFileType: false
        };
    }
});
</script>
