<template>
    <div
        class="d-ps-absolute d-r0 d-t0 d-h100p d-zi-drawer d-of-hidden d-bl d-blw1 d-bc-subtle"
        id="merge-client"
        v-if="open"
    >
        <dx-chatbot
            v-if="shouldShowMergeClient && shouldShowChatbotClient"
            v-model:bot-data="openedChatbot"
            :close-chatbot-drawer="closeChatbotDrawer"
        />
        <iframe-loader
            v-else-if="shouldShowMergeClient"
            @message="handleIframePostMessage"
            :src="iframeSrc"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, inject } from 'vue';
import type { DigitalExperienceProvider } from '@/open-api';
import type { KoopidBot } from '@/utils/koopid';
import IframeLoader from '@/components/iframe-loader/IframeLoader.vue';
import DxChatbot from '@/components/dx-chatbot/DxChatbot.vue';

export default defineComponent({
    components: {
        DxChatbot,
        IframeLoader
    },
    props: {
        open: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        koopidBot: {
            type: Object as PropType<KoopidBot>
        },
        closeChatbotDrawer: {
            type: Function as PropType<() => void>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    watch: {
        koopidBot(newKoopidBot: KoopidBot) {
            this.openedChatbot = newKoopidBot;
        }
    },
    computed: {
        shouldShowMergeClient(): boolean {
            return !!this.koopidBot;
        },
        shouldShowChatbotClient(): boolean {
            return this.$store.getters[`${this.orgId}/newWebchatClient`];
        },
        iframeSrc(): string {
            if (!this.koopidBot) return '';
            if (!this.koopidBot.url) return this.koopidBot.shortLink;

            // if url prop is present on koopidBot, we need to build the whole URL
            // and append the try now flag to force a new session each time the user opens an agent
            const koopid: DigitalExperienceProvider =
                this.$store.getters[`${this.orgId}/koopid`];
            const iframeUrl = new URL(this.koopidBot.url, koopid.url);
            iframeUrl.searchParams.append('trynow', 'true');
            return iframeUrl.toString();
        }
    },
    methods: {
        handleIframePostMessage(e: MessageEvent) {
            // If we receive a close event from the Merge client
            if (e.data.type === 'chat-closed') {
                this.closeChatbotDrawer?.();
            }
        }
    },
    data() {
        return {
            openedChatbot: undefined as any
        };
    }
});
</script>
