<template>
    <dt-recipe-combobox-multi-select
        class="labels-combobox"
        ref="comboboxMultiSelect"
        :selected-items="hideSelected ? [] : filteredSelectedItems"
        :label="label"
        :loading="isFetching"
        :disabled="isDisabled"
        @input="onComboboxInput"
        @select="onComboboxSelect"
        @remove="onComboboxRemove"
        @keyup.enter="onKeyupEnter"
        append-to="parent"
        @opened="onComboboxOpened"
        @click="handleOpenLabels"
        @focusout="handleClickOutside"
        :show-list="showList"
        :placeholder="$t('Select one or start typing')"
    >
        <template #list>
            <ul class="d-p0" v-if="!hasError">
                <dt-list-item
                    v-for="(item, i) in filteredList"
                    :key="item"
                    role="option"
                    navigation-type="arrow-keys"
                    @click="onComboboxSelect(i)"
                >
                    {{ item }}
                </dt-list-item>
            </ul>
        </template>
    </dt-recipe-combobox-multi-select>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import {
    DtRecipeComboboxMultiSelect,
    DtListItem
} from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: {
        DtListItem,
        DtRecipeComboboxMultiSelect
    },
    props: {
        label: {
            type: String as PropType<string>,
            default: ''
        },
        selectedItems: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        itemList: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        allowCustomItems: {
            type: Boolean as PropType<boolean>,
            default: true
        },
        isFetching: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isDisabled: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hasError: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isFocused: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        hideSelected: {
            type: Boolean as PropType<boolean>,
            default: false
        }
    },
    mounted() {
        /* v8 ignore next 3 */
        if (this.isFocused) {
            (this.$refs.comboboxMultiSelect as any).getInput().focus();
        }
    },
    computed: {
        filteredSelectedItems(): string[] {
            return this.selectedItems;
        },
        filteredList(): string[] {
            return (
                this.itemList
                    ?.filter(
                        (item: string) =>
                            !this.selectedItems?.some(
                                (selectedItem: string) => selectedItem === item
                            )
                    )
                    /* v8 ignore next 6 */
                    .filter((item: string) => {
                        if (!this.inputLabelValue.length) return true;
                        return item
                            .toLowerCase()
                            .includes(this.inputLabelValue.toLowerCase());
                    }) || []
            );
        }
    },
    methods: {
        onComboboxInput(eventValue: Event) {
            this.inputLabelValue = eventValue.toString();
        },
        onComboboxSelect(eventIndex: Event | number) {
            const index = parseInt(eventIndex.toString());
            if (this.filteredList && this.filteredList[index]?.length)
                this.addLabel(this.filteredList[index]);
        },
        onComboboxRemove(item: string) {
            const index = this.selectedItems.findIndex(
                (selectedItem: string) => selectedItem === item
            );
            // This returns a new array without the selectedIndex item without mutating the array
            const newArray = this.selectedItems
                .slice(0, index)
                .concat(
                    this.selectedItems.slice(
                        index + 1,
                        this.selectedItems.length
                    )
                );

            this.$emit('update:selectedItems', newArray);
        },
        onKeyupEnter() {
            if (this.allowCustomItems && this.inputLabelValue.length)
                this.addLabel();
        },
        onComboboxOpened(isOpened: boolean) {
            if (!isOpened) {
                this.$emit('close');
            }
        },
        handleClickOutside() {
            this.showList = false;
        },
        addLabel(item?: string) {
            const itemToAdd = item || this.inputLabelValue;
            if (this.selectedItems?.includes(itemToAdd)) {
                this.inputLabelValue = '';
                return;
            }

            this.$emit('update:selectedItems', [
                ...this.selectedItems,
                itemToAdd
            ]);
            // Clear input box
            (this.$refs.comboboxMultiSelect as any).$data.value = '';
            (this.$refs.comboboxMultiSelect as any).closeComboboxList();
            this.inputLabelValue = '';
        },
        handleOpenLabels() {
            if (!this.itemList.length) {
                return;
            }
            this.showList = true;
        }
    },
    emits: ['update:selectedItems', 'close'],
    data() {
        return {
            inputLabelValue: '',
            showList: this.isFocused
        };
    }
});
</script>

<style lang="less">
.labels-combobox .d-input__wrapper {
    background: var(--dt-color-surface-primary);
    box-shadow: none;
}

.labels-combobox.no-border .d-input__wrapper {
    border: 0;
}
</style>
