/// A serializer for the schema.
import { MarkdownParser, MarkdownSerializer } from 'prosemirror-markdown';
import type { Mark, Node } from 'prosemirror-model';
import { schema } from '@/components/rich-text-editor/schema';
import MarkdownIt from 'markdown-it';
import type Token from 'markdown-it/lib/token';
// @ts-ignore
import { markdownItTable } from 'markdown-it-table';
// @ts-ignore
import markdownItReplacements from 'markdown-it-replacements';

/* v8 ignore next 225 */
function listIsTight(tokens: readonly Token[], i: number) {
    while (++i < tokens.length)
        if (tokens[i].type != 'list_item_open') return tokens[i].hidden;
    return false;
}

markdownItReplacements.replacements.push({
    name: 'noIframes',
    re: /\n*\s*<iframe.*?\\?>.*?<\/iframe\\?>\s*\n*/gi,
    sub: function () {
        return '';
    },
    default: true
});

const md = MarkdownIt('default')
    .use(markdownItTable)
    .use(markdownItReplacements);
export const markdownParser = new MarkdownParser(schema, md, {
    blockquote: { block: 'blockquote' },
    paragraph: { block: 'paragraph' },
    list_item: { block: 'list_item' },
    bullet_list: {
        block: 'bullet_list',
        getAttrs: (_, tokens, i) => ({ tight: listIsTight(tokens, i) })
    },
    ordered_list: {
        block: 'ordered_list',
        getAttrs: (tok, tokens, i) => ({
            order: +tok.attrGet('start')! || 1,
            tight: listIsTight(tokens, i)
        })
    },
    heading: {
        block: 'heading',
        getAttrs: (tok) => ({ level: +tok.tag.slice(1) })
    },
    code_block: { block: 'code_block', noCloseToken: true },
    fence: {
        block: 'code_block',
        getAttrs: (tok) => ({ params: tok.info || '' }),
        noCloseToken: true
    },
    hr: { node: 'horizontal_rule' },
    image: {
        node: 'image',
        getAttrs: (tok) => ({
            src: tok.attrGet('src'),
            title: tok.attrGet('title') || null,
            alt: (tok.children![0] && tok.children![0].content) || null
        })
    },
    hardbreak: { node: 'hard_break' },

    em: { mark: 'em' },
    strong: { mark: 'strong' },
    s: { mark: 's' },
    link: {
        mark: 'link',
        getAttrs: (tok) => ({
            href: tok.attrGet('href'),
            title: tok.attrGet('title') || null
        })
    },
    code_inline: { mark: 'code', noCloseToken: true },
    table: { block: 'table' },
    thead: {
        block: 'table_head'
    },
    tbody: {
        block: 'table_body'
    },
    tr: {
        block: 'table_row'
    },
    th: {
        block: 'table_header'
    },
    td: {
        block: 'table_cell'
    }
});

function backticksFor(node: Node, side: number) {
    const ticks = /`+/g;
    let m,
        len = 0;
    if (node.isText)
        while ((m = ticks.exec(node.text!))) len = Math.max(len, m[0].length);
    let result = len > 0 && side > 0 ? ' `' : '`';
    for (let i = 0; i < len; i++) result += '`';
    if (len > 0 && side < 0) result += ' ';
    return result;
}

function isPlainURL(link: Mark, parent: Node, index: number) {
    if (link.attrs.title || !/^\w+:/.test(link.attrs.href)) return false;
    const content = parent.child(index);
    if (
        !content.isText ||
        content.text != link.attrs.href ||
        content.marks[content.marks.length - 1] != link
    )
        return false;
    return (
        index == parent.childCount - 1 ||
        !link.isInSet(parent.child(index + 1).marks)
    );
}

export const markdownSerializer = new MarkdownSerializer(
    {
        blockquote(state, node) {
            state.wrapBlock('> ', null, node, () => state.renderContent(node));
        },
        code_block(state, node) {
            // Make sure the front matter fences are longer than any dash sequence within it
            const backticks = node.textContent.match(/`{3,}/gm);
            const fence = backticks
                ? backticks.sort().slice(-1)[0] + '`'
                : '```';

            state.write(fence + (node.attrs.params || '') + '\n');
            state.text(node.textContent, false);
            // Add a newline to the current content before adding closing marker
            state.write('\n');
            state.write(fence);
            state.closeBlock(node);
        },
        heading(state, node) {
            state.write(state.repeat('#', node.attrs.level) + ' ');
            state.renderInline(node);
            state.closeBlock(node);
        },
        horizontal_rule(state, node) {
            state.write(node.attrs.markup || '---');
            state.closeBlock(node);
        },
        bullet_list(state, node) {
            state.renderList(
                node,
                '  ',
                () => (node.attrs.bullet || '*') + ' '
            );
        },
        ordered_list(state, node) {
            const start = node.attrs.order || 1;
            const maxW = String(start + node.childCount - 1).length;
            const space = state.repeat(' ', maxW + 2);
            state.renderList(node, space, (i) => {
                const nStr = String(start + i);
                return state.repeat(' ', maxW - nStr.length) + nStr + '. ';
            });
        },
        list_item(state, node) {
            state.renderContent(node);
        },
        paragraph(state, node) {
            state.renderInline(node);
            state.closeBlock(node);
        },

        image(state, node) {
            state.write(
                '![' +
                    state.esc(node.attrs.alt || '') +
                    '](' +
                    node.attrs.src.replace(/[()]/g, '\\$&') +
                    (node.attrs.title
                        ? ' "' + node.attrs.title.replace(/"/g, '\\"') + '"'
                        : '') +
                    ')'
            );
        },
        hard_break(state, node, parent, index) {
            for (let i = index + 1; i < parent.childCount; i++)
                if (parent.child(i).type != node.type) {
                    state.write('\\\n');
                    return;
                }
        },
        text(state, node) {
            // @ts-ignore
            state.text(node.text!, !state.inAutolink);
        }
    },
    {
        em: {
            open: '*',
            close: '*',
            mixable: true,
            expelEnclosingWhitespace: true
        },
        strong: {
            open: '**',
            close: '**',
            mixable: true,
            expelEnclosingWhitespace: true
        },
        s: {
            open: '~~',
            close: '~~',
            mixable: false,
            expelEnclosingWhitespace: true
        },
        link: {
            open(state, mark, parent, index) {
                // @ts-ignore
                state.inAutolink = isPlainURL(mark, parent, index);
                // @ts-ignore
                return state.inAutolink ? '<' : '[';
            },
            close(state, mark) {
                // @ts-ignore
                const { inAutolink } = state;
                // @ts-ignore
                state.inAutolink = undefined;
                return inAutolink
                    ? '>'
                    : '](' +
                          mark.attrs.href.replace(/[()"]/g, '\\$&') +
                          (mark.attrs.title
                              ? ` "${mark.attrs.title.replace(/"/g, '\\"')}"`
                              : '') +
                          ')';
            },
            mixable: true
        },
        code: {
            open(_state, _mark, parent, index) {
                return backticksFor(parent.child(index), -1);
            },
            close(_state, _mark, parent, index) {
                return backticksFor(parent.child(index - 1), 1);
            },
            escape: false
        }
    }
);
