<template>
    <drawer-template
        content-class="d-p0"
        header-class="d-bbw0 d-pb2"
        :drawer-content-height="maxContentHeight"
        :hide-close="hideClose"
        @drawer-template-close="close"
        @update:drawer-content-height="updateDrawerContentHeight"
    >
        <template #header>
            <div class="d-w100p d-box-border">
                <div class="d-headline-medium">
                    <slot name="headerTitle">
                        {{ $t('Details') }}
                    </slot>
                </div>
            </div>
        </template>
        <template #default>
            <div class="d-d-flex d-gg16 d-px16 d-pb8" ref="statsSection">
                <stat-counter
                    :type="StatCounterType.MissingAnswers"
                    :count="conversationMetadata.counters.queries.missing"
                />
                <stat-counter
                    :type="StatCounterType.Queries"
                    :count="conversationMetadata.counters.queries.total"
                />
                <stat-counter
                    :type="StatCounterType.ThumbsUp"
                    :count="conversationMetadata.counters.clicks.thumbs_ups"
                />
                <stat-counter
                    :type="StatCounterType.ThumbsDown"
                    :count="conversationMetadata.counters.clicks.thumbs_downs"
                />
            </div>
            <dt-tab-group selected="transcript-panel">
                <template #tabs>
                    <div ref="tabs" class="d-pt4">
                        <dt-tab
                            id="transcript"
                            panel-id="transcript-panel"
                            class="d-fs-100"
                        >
                            {{ $t('Transcript') }}
                        </dt-tab>
                        <dt-tab
                            id="metadata"
                            panel-id="metadata-panel"
                            class="d-fs-100"
                        >
                            {{ $t('Metadata') }}
                        </dt-tab>
                    </div>
                </template>

                <dt-tab-panel id="transcript-panel" tab-id="transcript">
                    <div class="d-h100p">
                        <div v-if="shouldShowAiAssist">
                            <agent-assist-widget
                                :key="appId"
                                :app-id="appId"
                                top-bar-hidden="true"
                                auto-open="true"
                                :height="widgetHeight"
                                :read-only="readOnly"
                                :stateless="stateless"
                                :dark-mode="darkMode"
                                :zone="zone"
                                :width="width"
                                :office-name="officeName"
                                :timezone="timezone"
                                :locale="locale"
                            />
                        </div>
                        <div
                            v-else
                            class="d-d-flex d-jc-center d-ai-center d-w100p d-box-border"
                            style="height: calc(100vh - 64px)"
                        >
                            <dt-button kind="inverted" loading />
                        </div>
                    </div>
                </dt-tab-panel>
                <dt-tab-panel id="metadata-panel" tab-id="metadata">
                    <div class="d-h100p d-of-hidden">
                        <div
                            class="d-px16 d-py12 d-d-flex d-fw-wrap d-of-y-auto d-box-border d-ac-flex-start d-gg16"
                            :style="{
                                height: `${widgetHeight}px`
                            }"
                        >
                            <div
                                v-for="data in historyData"
                                :key="data.value"
                                class="d-d-flex d-fw-wrap d-w100p d-fd-column"
                            >
                                <span class="d-headline-small d-fc-tertiary">
                                    {{ $t(data.title) }}
                                </span>
                                <span class="d-body-base d-wb-break-all">
                                    {{ data.value }}
                                </span>
                            </div>
                            <div
                                v-for="(
                                    metadata, metadataIndex
                                ) in customMetadata"
                                :key="`metadata-${metadataIndex}`"
                                class="d-d-flex d-fw-wrap d-w100p"
                            >
                                <span
                                    class="d-w100p d-headline-small d-fc-tertiary"
                                >
                                    {{ capitalizeString(metadata.key) }}
                                </span>
                                <span
                                    class="d-body-base d-tt-capitalize d-wb-break-all"
                                >
                                    {{ metadata.value }}
                                </span>
                            </div>
                            <div class="d-d-flex d-fw-wrap d-w100p">
                                <span
                                    class="d-w100p d-headline-small d-fc-tertiary"
                                >
                                    {{ $t('Labels') }}
                                </span>
                                <div class="d-d-flex d-fw-wrap d-gg4">
                                    <dt-chip
                                        v-for="(
                                            label, labelIndex
                                        ) in conversationMetadata.labels"
                                        :key="`label-${conversationMetadata.id}-${labelIndex}`"
                                        :interactive="false"
                                        hide-close
                                    >
                                        {{ label }}
                                    </dt-chip>
                                    <template
                                        v-if="
                                            !conversationMetadata.labels.length
                                        "
                                    >
                                        {{ $t('No labels') }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </dt-tab-panel>
            </dt-tab-group>
            <div
                class="d-d-flex d-py8 d-jc-center d-bt d-btw1 d-bc-subtle"
                ref="summary"
                v-if="shouldShowSummary"
            >
                <dt-button
                    importance="clear"
                    icon-position="right"
                    size="sm"
                    @click="handleOnClick"
                >
                    {{ $t('View summary') }}
                    <template #icon>
                        <dt-icon name="external-link" size="300" />
                    </template>
                </dt-button>
            </div>
        </template>
    </drawer-template>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, inject } from 'vue';
import {
    DtButton,
    DtChip,
    DtIcon,
    DtInput,
    DtLink,
    DtListItem,
    DtNotice,
    DtSelectMenu,
    DtTab,
    DtTabGroup,
    DtTabPanel
} from '@dialpad/dialtone/vue3';
import DropFile from '@/components/upload-drawer/DropFile.vue';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import type { DrawerService } from '@/services/Drawer.service';
import { type DIALPAD_LOCALES, ZONES_ABBR } from '@/utils/Constants';
import { Conversation, LicenseType, Widget } from '@/open-api';
import {
    capitalizeString,
    getFormattedDate,
    getFormattedHour
} from '@/utils/Common';
import StatCounter, {
    StatCounterType
} from '@/components/history-drawer/StatCounter.vue';

export default defineComponent({
    components: {
        StatCounter,
        DtChip,
        DtButton,
        DtIcon,
        DtInput,
        DtTab,
        DtTabGroup,
        DtTabPanel,
        DtNotice,
        DtLink,
        DtSelectMenu,
        DtListItem,
        DropFile,
        DrawerTemplate,
        WebComponentLoader
    },
    props: {
        appId: {
            type: String as PropType<string>
        },
        appName: {
            type: String as PropType<string>,
            required: true
        },
        conversationMetadata: {
            type: Object as PropType<Conversation>,
            required: true
        },
        aiAssistLoading: {
            required: true
        },
        readOnly: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        stateless: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        width: {
            type: Number,
            default: 360
        },
        hideClose: {
            type: Boolean as PropType<boolean>,
            default: false
        }
    },
    emits: ['close'],
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    data() {
        return {
            widgetHeight: 0,
            // these below are sensitive defaults in case refs are not present yet
            tabHeight: 34 + 4, // tab height + padding
            statsSectionHeight: 28 + 8, // height + padding,
            summaryHeight: 0
        };
    },
    computed: {
        StatCounterType() {
            return StatCounterType;
        },
        // This is done so that the function coverage isn't affected by computed props
        /* v8 ignore next 33 */
        shouldShowAiAssist(): boolean {
            return !this.aiAssistLoading && !!this.maxContentHeight;
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        darkMode(): boolean {
            return this.$store.getters[`${this.orgId}/darkMode`];
        },
        maxContentHeight(): number {
            return this.$store.getters[`${this.orgId}/maxContentHeight`];
        },
        zone(): ZONES_ABBR {
            return this.$store.getters[`${this.orgId}/zone`];
        },
        formattedDateTime(): string {
            if (this.conversationMetadata.first_message_time) {
                return `${getFormattedDate(
                    this.conversationMetadata.first_message_time,
                    this.locale
                )} ${this.$t('at')} ${getFormattedHour(
                    this.conversationMetadata.first_message_time,
                    this.locale
                )}`;
            }
            return 'No date';
        },
        customMetadata(): { key: string; value: any }[] {
            if (!this.conversationMetadata.metadata) return [];
            try {
                const metadataObj = this.conversationMetadata.metadata;
                return Object.keys(metadataObj)
                    .filter((md) => md.startsWith('m-'))
                    .map((key) => ({
                        // @ts-ignore
                        key: key.replaceAll('m-', '').replaceAll('-', ' '),
                        value: metadataObj[key]
                    }));
                /* v8 ignore next 3 */
            } catch (e) {
                return [];
            }
        },
        historyData(): { title: string; value: any }[] {
            return [
                {
                    title: 'Conversation ID',
                    value: this.conversationMetadata.id
                },
                {
                    title: 'Conversation started',
                    value: this.formattedDateTime
                }
            ];
        },
        conversationId(): string | undefined {
            return this.conversationMetadata.id?.split('conv_')[1] || undefined;
        },
        userId(): string | undefined {
            return this.conversationMetadata.metadata['m-user-id'] || undefined;
        },
        callId(): string | undefined {
            return this.conversationMetadata.metadata['m-call-id'] || undefined;
        },
        isAiAgent(): boolean {
            return this.chatbot?.license_type === LicenseType.Dss;
        },
        isAiAssistant(): boolean {
            return this.chatbot?.license_type === LicenseType.Aaa;
        },
        chatbot(): Widget {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        },
        isDigitalConversation(): boolean {
            // Check if conversation follows the pattern of XXXXX.XXXXX
            return /^[^.]+\..*[^-_.]$/g.test(this.conversationId);
        },
        shouldShowSummary(): boolean {
            return this.isAiAgent
                ? !!this.userId?.length &&
                      !!this.conversationId?.length &&
                      this.isDigitalConversation
                : false;
            // TODO(joaonoronha): When we add Ai Assistant deeplinking we should use the condition below
            // return this.isAiAgent
            //     ? !!this.userId?.length && !!this.conversationId?.length
            //     : this.isAiAssistant
            //       ? !!this.callId?.length
            //       : false;
        },
        officeName(): string | undefined {
            return this.$store.getters[`${this.orgId}/officeName`];
        },
        timezone(): string | undefined {
            return this.$store.getters[`${this.orgId}/timezone`];
        },
        locale(): DIALPAD_LOCALES {
            return this.$store.getters[`${this.orgId}/locale`];
        }
    },
    methods: {
        close() {
            /* v8 ignore next 1 */
            this.drawerService?.closeDrawer();
            this.$emit('close');
        },
        updateDrawerContentHeight(drawerContentHeight: number) {
            if (!drawerContentHeight) return;

            const statsSectionHeight =
                this.$refs.statsSection?.clientHeight ||
                this.statsSectionHeight;
            const tabsHeight = this.$refs.tabs?.clientHeight || this.tabsHeight;
            const summaryHeight =
                this.$refs.summary?.clientHeight + 1 || this.summaryHeight; // +1 is to account for the border top

            this.widgetHeight =
                drawerContentHeight -
                statsSectionHeight -
                tabsHeight -
                summaryHeight;
        },
        capitalizeString,
        handleOnClick() {
            const origin = window.location.origin;

            let deepLinkWCH = undefined;
            const path = this.isAiAgent
                ? `digitalsessionsummary/${this.conversationId}?customerId=${this.userId}`
                : `callhistory/callreview/${this.callId}?source=call-history%3A`;

            if (origin.startsWith('https://dialpad')) {
                deepLinkWCH = new URL(path, origin);
            } else {
                // Try to redirect to dialpad URL depending on kare zone
                const fallbackOrigin =
                    this.zone === ZONES_ABBR.DEVELOP ||
                    this.zone === ZONES_ABBR.STAGING
                        ? 'https://dialpadbeta.com'
                        : 'https://dialpad.com';
                deepLinkWCH = new URL(path, fallbackOrigin);
            }

            window.open(deepLinkWCH.toString(), '_blank').focus();
        }
    }
});
</script>
