<template>
    <dt-dropdown
        v-bind="$attrs"
        :append-to="appendTo === 'body' ? bodyRef : 'parent'"
        :placement="placement"
        :list-class="{
            focused: focused,
            ['d-bgc-secondary']: true
        }"
        padding="small"
        @opened="toggleOpen"
        :modal="false"
        transition="none"
    >
        <template #anchor="{ attrs }">
            <dt-button
                :class="{ 'd-bgc-black-200': shown }"
                v-bind="attrs"
                kind="muted"
                importance="clear"
                @click="handleClick"
                :disabled="loading || disabled"
                :active="activeButton"
            >
                <template #icon>
                    <dt-icon :name="iconName" :size="iconSize" />
                </template>
            </dt-button>
        </template>
        <template #list="{ close }">
            <dt-list-item
                v-for="(
                    option, optionIndex
                ) in options as IMenuPopoverOptions[]"
                :key="`option-${optionIndex}`"
                role="menuitem"
                @click="handleAction($event, option, close, rowData)"
                class="d-c-pointer"
                :class="[
                    getClassByKind(option, rowData),
                    getMarginByIndex(optionIndex, options)
                ]"
            >
                {{ $t(option?.title) }}
            </dt-list-item>
        </template>
    </dt-dropdown>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtIcon,
    DtListItem,
    DtDropdown
} from '@dialpad/dialtone/vue3';
import type { IMenuPopoverOptions } from '@/components/menu-popover/MenuPopover.types';
import { POPOVER_TYPES } from '@/components/menu-popover/MenuPopover.types';

export default defineComponent({
    props: {
        id: {
            required: true,
            type: String as PropType<string>
        },
        loading: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array as PropType<IMenuPopoverOptions[]>
        },
        rowData: {
            type: Object as PropType<any>
        },
        iconSize: {
            type: String as PropType<string>,
            default: '300'
        },
        iconName: {
            type: String as PropType<string>,
            default: 'more-vertical'
        },
        activeButton: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        focused: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        appendTo: {
            type: String as PropType<string>,
            default: 'body'
        },
        placement: {
            type: String as PropType<string>,
            default: 'bottom-end'
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    components: {
        DtButton,
        DtListItem,
        DtIcon,
        DtDropdown
    },
    computed: {
        IMenuPopoverOptions(): IMenuPopoverOptions {
            // @ts-ignore
            return IMenuPopoverOptions;
        },
        bodyRef(): HTMLElement {
            return this.$store.getters[`${this.orgId}/bodyRef`];
        }
    },
    methods: {
        getClassByKind(option: any, rowData: any): string {
            if (option?.disabled?.(rowData)) {
                return 'd-bgc-black-300 d-c-not-allowed';
            }

            switch (option.kind) {
                case POPOVER_TYPES.DANGER:
                    return 'd-fc-red-300 h:d-bgc-red-100 h:d-fc-red-400';
                default:
                    return 'h:d-bgc-black-300';
            }
        },
        getMarginByIndex(index: number, options?: any[]): string {
            if (index === 0) return 'd-mt4';
            else if (options?.length && options.length - 1 === index)
                return 'd-mb4';
            return '';
        },
        async toggleOpen(isOpen: boolean) {
            if (isOpen) this.openModal();
            else this.closeModal();

            await this.$nextTick();
            const tippy = this.bodyRef.querySelector('.tippy-box');
            if (tippy && this.appendTo === 'body')
                tippy.classList.add('focused');
        },
        closeModal() {
            this.shown = false;
        },
        handleAction(event: Event, option: any, close: any, rowData: any) {
            if (option?.disabled?.(rowData)) return;
            if (close) close();
            event.stopPropagation();
            this.togglePopover();
            if (option.action) option.action(rowData);
        },
        handleClick(event: Event) {
            event.stopPropagation();
            this.$emit('click', event);
            this.togglePopover();
        },
        openModal() {
            this.shown = true;
        },
        togglePopover() {
            if (!this.shown) {
                this.openModal();
            } else {
                this.closeModal();
            }
        }
    },
    emits: ['click'],
    data() {
        return {
            shown: false
        };
    }
});
</script>

<style lang="less">
.focused {
    z-index: 300 !important;
}
</style>
